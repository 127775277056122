import { render, staticRenderFns } from "./authenticate.vue?vue&type=template&id=1e990bc6&scoped=true"
import script from "./authenticate.vue?vue&type=script&lang=js"
export * from "./authenticate.vue?vue&type=script&lang=js"
import style0 from "./authenticate.vue?vue&type=style&index=0&id=1e990bc6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e990bc6",
  null
  
)

export default component.exports