<template>
  <div class="content">
    <div class="page_title">
      <img @click="goBack" src="../assets/images/detail/left_back.png" alt />
      <span>鉴定信息</span>
    </div>
    <div class="cont_bot">
      <!-- 鉴定说明 -->
      <div class="diagProcedure">
        <div>
          <span class="required">鉴定说明</span>
          <textarea
            v-model="formData.explain"
            name="process"
            cols="40"
            rows="5"
            placeholder="请输入鉴定说明"
          ></textarea>
        </div>
      </div>
      <!-- 返修原因 -->
      <div class="conclusion">
        <span class="required">返修原因</span>
        <ul class="check_css3">
          <li>
            <input
              name="protocol"
              type="checkbox"
              class="input_check"
              value="备件原因"
              v-model="formData.reason"
              id="check1"
            />
            <label for="check1"></label>备件原因
          </li>
          <li>
            <input
              name="protocol"
              type="checkbox"
              class="input_check"
              value="工、量具设备、设施原因"
              v-model="formData.reason"
              id="check2"
            />
            <label for="check2"></label>工、量具设备、设施原因
          </li>
          <li>
            <input
              name="protocol"
              type="checkbox"
              class="input_check"
              value="主修工技能不足"
              v-model="formData.reason"
              id="check3"
            />
            <label for="check3"></label>主修工技能不足
          </li>
          <li>
            <input
              name="protocol"
              type="checkbox"
              class="input_check"
              value="主修工操作不当"
              v-model="formData.reason"
              id="check4"
            />
            <label for="check4"></label>主修工操作不当
          </li>
          <li>
            <input
              name="protocol"
              type="checkbox"
              class="input_check"
              value="诊断技师诊断错误"
              v-model="formData.reason"
              id="check5"
            />
            <label for="check5"></label>诊断技师诊断错误
          </li>
          <li>
            <input
              name="protocol"
              type="checkbox"
              class="input_check"
              value="竣工检验未正确执行标准"
              v-model="formData.reason"
              id="check6"
            />
            <label for="check6"></label>竣工检验未正确执行标准
          </li>
          <li>
            <input
              name="protocol"
              type="checkbox"
              class="input_check"
              value="过程检验未正确执行标准"
              v-model="formData.reason"
              id="check7"
            />
            <label for="check7"></label>过程检验未正确执行标准
          </li>
          <li>
            <input
              name="protocol"
              type="checkbox"
              class="input_check"
              value="非返修"
              v-model="formData.reason"
              id="check8"
            />
            <label for="check8"></label>非返修
          </li>
          <li class="row">
            <input
              name="protocol"
              type="checkbox"
              class="input_check"
              value="其他原因:"
              v-model="formData.reason"
              id="check9"
              @click="handelReason($event)"
            />
            <label for="check9"  style="position: relative;top:0.2rem"></label><span style="width:0.9rem;vertical-align: middle;">其他原因:</span>
            <input style="vertical-align: middle;"
              v-model="otherReason"
              class="text"
              type="text"
              :disabled="disabled"
              placeholder="请输入其他原因"
            />
          </li>
        </ul>
      </div>
      <!-- 责任确定 -->
      <div class="duty">
        <span class="required">责任确定</span>
        <div>
          <span>主要责任人</span>
          <select name="main" v-model="formData.main_people">
            <option value="0">请选择主要责任人</option>
            <template v-for="(item,idx) in list">
              <option :value="item.staff_name">{{item.staff_name}}</option>
            </template>
          </select>
        </div>
        <div>
          <span>次要责任人</span>
          <select name="main" v-model="formData.secondary_people">
            <option value="0">请选择次要责任人</option>
            <template v-for="(item,idx) in list">
              <option :value="item.staff_name">{{item.staff_name}}</option>
            </template>
          </select>
        </div>
      </div>
      <!-- 维修方案 -->
      <div class="scheme">
        <div>
          <span class="required">维修方案</span>
          <textarea
            v-model="formData.maintenance_plan"
            name="process"
            id
            cols="40"
            rows="5"
            placeholder="请输入维修方案"
          ></textarea>
        </div>
      </div>
      <div class="scheme">
        <div>
          <span class>备注信息</span>
          <textarea
            v-model="formData.remarks"
            name="process"
            id
            cols="40"
            rows="5"
            placeholder="请输入备注信息"
          ></textarea>
        </div>
      </div>
      <div style="height:0.84rem"></div>
      <div v-if="type==2" class="submit_btn" @click="submit">提交鉴定信息</div>
      <div v-else class="submit_btn" @click="submitAgain">提交鉴定信息</div>
    </div>
    <GoHome></GoHome>
  </div>
</template>

<script>
import port from "@/api/port";
import cookies from "@/utils/cookie.js";

export default {
  name: "authenticate", //鉴定
  data() {
    return {
      id: this.$route.query.id, //详情id
      work_order_status: this.$route.query.work_order_status,
      disabled: true,
      otherReason: "", //其他原因
      formData: {
        explain: "", //鉴定说明
        reason: [], //返修原因
        main_people: 0, //主要责任人
        secondary_people: 0, //次要责任人
        maintenance_plan: "", //维修方案
        remarks: "" //备注信息
      },
      list:[],
      type: "2"
    };
  },
  methods: {
    submit(event) {
      let _this = this;
      if (!this.disabled) {
        if (this.formData.reason.indexOf("其他原因:") != -1) {
          this.formData.reason[this.formData.reason.indexOf("其他原因:")] =
            this.formData.reason[this.formData.reason.indexOf("其他原因:")] +
            this.otherReason;
        }
      }
      this.formData = {
        ...this.formData
      };
      console.log({ ...this.formData });
      if (this.formData.explain == "") {
        this.$toast({ message: "请填写鉴定说明" });
        return false;
      }
      if (this.formData.reason.length == 0) {
        this.$toast({ message: "请填写返修原因" });
        return false;
      }

      if (this.disabled == false) {
        this.disabled = false;
        if(this.otherReason==''){
          this.$toast({ message: "请填写其他原因" });
          return false;
        }
      }
      if(this.formData.main_people == 0){
        this.$toast({ message: '请选择主要责任人' });
        return false;
      }
      if(this.formData.secondary_people == 0){
        this.$toast({ message: '请选择次要责任人' });
        return false;
      }
      if (this.formData.maintenance_plan == "") {
        this.$toast({ message: "请填写维修方案" });
        return false;
      }

      let params = {
        method: "200007",
        work_id: this.id,
        token: cookies.get("token"),
        ...this.formData
      };
      port.portAddress(params).then(res => {
        if (res.status == 200) {
          this.$router.push({
            path: "/reworkingDetail",
            query: {
              id: _this.id
            }
          });
          cookies.set("re_success", 'success');
        } else {
          this.$toast({ message: res.msg });
        }
      });
    },
    submitAgain() {
      let _this = this;
      if (!this.disabled) {
        if (this.formData.reason.indexOf("其他原因:") != -1) {
          this.formData.reason[this.formData.reason.indexOf("其他原因:")] =
            this.formData.reason[this.formData.reason.indexOf("其他原因:")] +
            this.otherReason;
        }
      }

      var re = /^其他原因:$/;
      _this.formData.reason.forEach((t, i) => {
        if (re.test(t)) {
          _this.formData.reason.splice(i, 1);
        }
      });

      if (this.formData.explain == "") {
        this.$toast({ message: "请填写鉴定说明" });
        return false;
      }
      if (this.formData.reason.length == 0) {
        this.$toast({ message: "请填写返修原因" });
        return false;
      }

      if (this.disabled == false) {
        this.disabled = false;
        if(this.otherReason==''){
          this.$toast({ message: "请填写其他原因" });
          return false;
        }
      }
      if(this.formData.main_people == 0){
        this.$toast({ message: '请选择主要责任人' });
        return false;
      }
      if(this.formData.secondary_people == 0){
        this.$toast({ message: '请选择次要责任人' });
        return false;
      }
      if (this.formData.maintenance_plan == "") {
        this.$toast({ message: "请填写维修方案" });
        return false;
      }

      this.formData = {
        ...this.formData
      };
      console.log({ ...this.formData });

      let params = {
        method: "200008",
        work_id: this.id,
        token: cookies.get("token"),
        ...this.formData
      };
      port.portAddress(params).then(res => {
        if (res.status == 200) {
          this.$router.push({
            path: "/reworkingDetail",
            query: {
              id: _this.id
            }
          });
          cookies.set("re_success", 'success');
        } else {
          this.$toast({ message: res.msg });
        }
      });
    },
    handelReason(e) {
      let checked = e.target.checked;
      let _this = this;
      if (checked) {
        this.disabled = false;
      } else {
        this.disabled = true;
        this.otherReason = "";
        _this.formData.reason.forEach((t, i) => {
          if (t.split(":")[0] == "其他原因") {
            _this.formData.reason.splice(i, 1);
          }
        });
      }
    }
  },
  created() {
    let _this = this;
    if (this.work_order_status != 2) {
      _this.type = this.work_order_status;
      let params = {
        method: "200010",
        work_id: this.id,
        token: cookies.get("token")
      };
      port.portAddress(params).then(res => {
        if (res.status == 200) {
          _this.formData = res.data;
          res.data.reason.forEach(t => {
            if (t.split(":")[0] == "其他原因") {
              _this.otherReason = t.split(":")[1];
              _this.formData.reason.push("其他原因:");
            }
          });
        } else {
          this.$toast({ message: res.msg });
        }
      });
    }

    //转给同事列表
    let params1 = {
      method: "200012",
      work_id: this.id,
      token: cookies.get("token")
    };
    port.portAddress(params1).then(res => {
      if (res.status == 200) {
        _this.list = res.data;
      } else {
        this.$toast({ message: res.msg });
      }
    });
  }
};
</script>

<style scoped lang="less">
.content {
  .cont_bot {
    padding-top: 0.75rem;
  }
  // 诊断过程
  .diagProcedure,
  .scheme,
  .duty {
    padding: 0.41rem 0.27rem;
    box-sizing: border-box;
    margin-bottom: 0.14rem;
    background-color: #fff;
    & > div {
      display: flex;
    }
    span {
      font-size: 0.24rem;
      vertical-align: top;
      width: 1.2rem;
      color: #333;
      margin-right: 0.2rem;
    }
    textarea {
      width: 4.35rem;
      height: 1.88rem;
      border-radius: 0.1rem;
      border: 0.02rem solid rgba(237, 237, 237, 1);
      padding: 0.2rem 0 0 0.22rem;
      box-sizing: border-box;
    }
  }

  //返修原因
  .conclusion {
    background-color: #fff;
    & > span {
      display: block;
      padding-top: 0.41rem;
      padding-left: 0.27rem;
      box-sizing: border-box;
    }
    .check_css3 {
      margin-top: 0.27rem;
      margin-left: 0.27rem;
      font-size: 0;
      li {
        margin-bottom: 0.26rem;
        font-size: 0.2rem;
        display: inline-block;
        width: 2.7rem;
        vertical-align: top;
        label {
          display: inline-block;
          margin-right: 0.1rem;
          vertical-align: top;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          width: 2.3rem;
        }
        input {
          vertical-align: middle;
          text-indent: 0.2rem;
        }
        .text {
          width: 3.92rem;
          height: 0.58rem;
          border-radius: 0.03rem;
          border: 0.02rem solid #d4d4d4;
        }
      }
      .row {
        padding-right: 0.27rem;
        box-sizing: border-box;
        width: 100%;
        span {
          display: inline-block;
          vertical-align: top;
        }
        label {
          position: relative;
          top: 0.15rem;
        }
      }
      .input_check {
        position: absolute;
        visibility: hidden;
      }
      .input_check + label {
        display: inline-block;
        width: 0.24rem;
        height: 0.24rem;
        border: 0.02rem solid #c4c4c4;
        background-color: rgba(47, 138, 241, 0.1);
      }
      .input_check:checked + label:after {
        content: "";
        position: absolute;
        margin-top: 0.05rem;
        margin-left: 0.03rem;
        width: 0.13rem;
        height: 0.04rem;
        border: 0.02rem solid #fff;
        border-top-color: transparent;
        border-right-color: transparent;
        -ms-transform: rotate(-60deg);
        -moz-transform: rotate(-60deg);
        -webkit-transform: rotate(-60deg);
        transform: rotate(-45deg);
      }
      .input_check:checked + label {
        background-color: #2f8af1;
      }
    }
  }

  //责任确定
  .duty {
    & > span {
      display: block;
      margin: 0rem 0 0.41rem 0rem;
    }
    & > div {
      span {
        display: inline-block;
        margin: 0rem 0 0.27rem 0rem;
        width: 1.4rem;
        vertical-align: middle;
      }
    }
    select {
      vertical-align: middle;
      width: 3.92rem;
      height: 0.65rem;
      border-radius: 0.03rem;
      border: 0.02rem solid #d4d4d4;
      background-color: #fff;
      padding: 0.12rem 0.2rem;
      background: url("../assets/images/r-icon.png") no-repeat right center
        transparent;
      background-size: 0.34rem 0.34rem;
      color: #999;
      position: relative;
      top: -0.1rem;
      margin-bottom: 0.27rem;
    }
  }

  //维修方案
  .scheme,
  .duty {
    margin-top: 0.14rem;
  }
  .submit_btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 0.84rem;
    line-height: 0.84rem;
    text-align: center;
    color: #fff;
    font-size: .24rem;
    background:#2F8AF1;
    // background: rgb(172, 208, 249);
  }
}
</style>
